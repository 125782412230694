import css from 'styled-jsx/css';

import { rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PaymentsTable {
    :global(.RowLink) {
      justify-content: flex-start;
    }

    &__export-buttons {
      display: flex;
      gap: 15px;
      justify-content: ${isRTL ? 'flex-start' : 'flex-end'};

      &--select {
        width: 150px;

        .BaseSelectField {
          margin-bottom: 0;
          &__label {
            font-size: rem(15px);
            line-height: rem(22px);
            font-weight: 500;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .Select__control {
          min-height: 100% !important;
        }
      }
    }
    /* .ReactTable {
      .rt-thead {
        min-width: 850px !important;
        .rt-tr {
          .rt-th {
            &:first-child {
              flex: 35 0 auto !important;
              width: 35px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(2) {
              flex: 140 0 auto !important;
              width: 140px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(3) {
              flex: 80 0 auto !important;
              width: 80px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(4) {
              width: 140px !important;
              flex: 140 0 auto !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(5) {
              flex: 90 0 auto !important;
              width: 90px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
          }
        }
      }
      .rt-tbody {
        min-width: 850px !important;
        .rt-tr-group {
          .rt-tr {
            .rt-td {
              span {
                font-size: rem(14px);
              }
              &:first-child {
                flex: 35 0 auto !important;
                width: 35px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
              }
              &:nth-child(2) {
                flex: 140 0 auto !important;
                width: 140px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                span {
                  font-size: rem(14px);
                  position: relative;
                  &:hover {
                    &:after {
                      width: 0;
                    }
                  }
                  &:after {
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transition: all 0.4s ease;
                  }
                  &:hover {
                    border-color: transparent;
                  }
                }
              }
              &:nth-child(3) {
                flex: 80 0 auto !important;
                width: 80px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
              }
              &:nth-child(4) {
                padding-right: 10px;
                width: 140px !important;
                flex: 140 0 auto !important;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                span {
                  font-size: rem(11px);
                  overflow: hidden;
                  text-overflow: clip;
                  width: 100%;
                  &:after {
                    top: 47%;
                  }
                }
              }
              &:nth-child(5) {
                flex: 90 0 auto !important;
                width: 90px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                span {
                  font-size: rem(14px);
                }
              }
            }
          }
        }
      }
    } */

    .ResponsiveTable__thead {
      display: flex;
      min-width: 320px !important;

      span {
        &:first-child {
          flex: 35;
        }
        &:nth-child(2) {
          flex: 150;
        }
        &:nth-child(3) {
          flex: 120;
        }
        &:last-child {
          padding: 0;
          flex: 1;
        }
      }
    }

    .ResponsiveTable__tbody {
      min-width: 320px !important;

      .ResponsiveTable__row-item {
        flex: 60;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          span {
            width: 90% !important;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &:nth-child(2) {
          margin: 0 auto;
          /* flex: 150 0 auto !important; */
          /* width: 150px !important; */

          @include respond-to(xs) {
            /* max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            } */
          }
        }

        &:nth-child(3) {
          margin: 0 auto;
          /* flex: 120 0 auto !important; */
          /* width: 120px !important; */

          @include respond-to(xs) {
            /* max-width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            } */
          }
        }
      }

      .ResponsiveTable__details-item {
        padding-left: 20%;
      }
    }
  }

  /* ^^^ div-table */
  .PaymentsTable {
    .ReactTable {
      .rt-table {
        .rt-thead {
          .rt-tr {
            min-width: calc(100% - 12px);
            justify-content: space-between;
            margin: 0 6px 0 6px;
            padding: 0 18px 0 18px;
            display: grid;
            grid-template-columns:
              minmax(80px, 80px)
              minmax(150px, 1fr)
              minmax(110px, 1fr)
              minmax(110px, 1fr)
              minmax(110px, 1fr)
              minmax(110px, 1fr)
              minmax(150px, 1fr)
              minmax(130px, 1fr)
              minmax(130px, 1fr)
              minmax(130px, 1fr)
              minmax(150px, 1fr);

            @include respond-to(lg) {
              padding: 0 12px 0 12px;
            }
          }

          .rt-th {
            width: auto !important;
            white-space: normal;
            font-size: 13px;
            line-height: 20px;
            padding: 0 2px 0 2px;

            span {
              display: inline;
              font-size: 13px;
              line-height: 20px;
            }
          }
        }

        .rt-tbody {
          min-width: 0 !important;
          display: block;
          overflow: visible;

          .rt-tr-group {
            display: inline-block;
            min-width: calc(100% - 12px);
          }

          .rt-tr {
            display: grid;
            grid-template-columns:
              minmax(80px, 80px)
              minmax(150px, 1fr)
              minmax(110px, 1fr)
              minmax(110px, 1fr)
              minmax(110px, 1fr)
              minmax(110px, 1fr)
              minmax(150px, 1fr)
              minmax(130px, 1fr)
              minmax(130px, 1fr)
              minmax(130px, 1fr)
              minmax(150px, 1fr);
          }

          .rt-td {
            width: auto !important;
            white-space: normal;
            font-size: 13px;
            line-height: 20px;
            padding: 0 2px 0 2px;

            .status {
              span {
                display: block;
                line-height: 14px;
              }
            }

            span {
              display: inline;
              font-size: 13px;
              line-height: 20px;
            }
          }
        }
      }
    }

    .ResponsiveTable {
      margin: -20px 0 0 0;

      &__thead {
        padding: 10px 19px 10px 10px;

        span {
          padding: 0 0 0 0;
          width: 50%;
          text-align: left !important;
          flex: 1 0 auto !important;

          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(5) {
            display: none;
          }
        }
      }

      &__tbody {
      }

      &__row {
        @include respond-to(sm) {
          margin: 0 0 2px 0;
          box-shadow: none !important;
        }
      }

      &__row-preview {
      }

      &__preview-main {
        display: grid !important;
        height: auto !important; /* 50px */
        grid-template-columns: 1fr 1fr;
      }

      &__row-item {
        display: block !important;
        text-align: left;
        width: auto !important;
        margin: 0 0 0 0 !important;
        overflow: hidden;

        .DateCell {
          display: flex;
          flex-wrap: wrap;

          span {
            color: #777777;

            &:nth-of-type(2) {
              display: none !important;
            }
          }
        }

        &:nth-of-type(1) {
          order: 1;
        }

        &:nth-of-type(2) {
          order: 3;
          font-weight: 500;
        }

        &:nth-of-type(3) {
          order: 4;
        }

        &:nth-of-type(4) {
          order: 2;
          white-space: normal;
        }
      }

      &__row-details {
        padding: 0 0 10px 0;
      }

      &__details-item {
        padding: 0 0 0 10px !important;
        margin: 0 0 8px 0 !important;

        &:last-child {
          margin: 0 0 0 0 !important;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .PaymentsTable {
      &__export-buttons--select {
        .BaseSelectField {
          .Select__control {
            border-color: ${colors.primary.hex} !important;
            &::after {
              border-color: ${colors.primary.hex};
            }
          }

          &__label {
            color: ${colors.primary.hex};
          }
        }
      }

      .ReactTable {
        .rt-tbody {
          .rt-tr-group {
            .rt-tr {
              .rt-td {
                &:nth-child(1) {
                  a {
                    span {
                      color: ${colors.black.hex};
                    }
                  }
                }

                &:nth-child(2) {
                  span {
                    color: ${colors.secondary.hex};
                    &:after {
                      background: ${rgba([colors.secondary.rgb, 0.6])};
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
