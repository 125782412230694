import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Gravatar from 'react-gravatar';
import md5 from 'blueimp-md5';

import { DateCell, DefaultButton, NewPaginationTable, RowLink } from 'common/components';
import { InnerPartnershipUsersTable } from '..';

import { staticStyles } from './style';

const PartnershipUsersTable = ({
  data: newData,
  history,
  getList,
  loading,
  location,
  params,
  viewEmailPermission,
  viewPhonePermission,
  viewSubIbTreePermission,
}) => {
  const [data, setData] = useState(newData);
  useEffect(() => {
    setData(newData);
  }, [loading]);

  /* eslint-disable */
  const columns = [
    {
      accessor: 'lastName',
      Header: 'justFullName',
      isSortable: true,

      Cell: ({ rowData: { email, fullName, id } }) => (
        <RowLink className="PartnershipUsersTable__user__link" link={`/partnership/clients/user/${id}/accounts`}>
          <div className="PartnershipUsersTable__user">
            {viewEmailPermission && <Gravatar md5={md5(email)} size={35} />}
            <span>{fullName}</span>
          </div>
        </RowLink>
      ),
    },
    ...(viewSubIbTreePermission
      ? [
          {
            Header: 'justIBOrClient',
            accessor: 'isPartner',
            isSortable: true,
            Cell: ({ rowData: { isPartner } }) => <span>{isPartner ? 'Sub_IB 1st level' : 'Client'}</span>,
          },
        ]
      : []),
    ...(viewEmailPermission
      ? [
          {
            Header: 'justEmail',
            accessor: 'email',
            isSortable: true,
            Cell: ({ rowData: { email } }) => <span>{email || '一'}</span>,
          },
        ]
      : []),

    ...(viewPhonePermission
      ? [
          {
            Header: 'justPhone',
            accessor: 'phone',
            isSortable: true,
            Cell: ({ rowData: { phone } }) => <span>{phone || '一'}</span>,
          },
        ]
      : []),
    {
      Header: 'justDateJoined',
      accessor: 'dateJoined',
      isSortable: true,
      Cell: ({ rowData: { dateJoined } }) => <DateCell value={dateJoined} />,
    },

    ...(viewSubIbTreePermission
      ? [
          {
            Header: 'justActions',
            accessor: 'actions',
            canExpand: true,
            Cell: ({ rowData: { isPartner, id }, isExpanded }) => {
              const handleShowClientsButton = e => {
                e.stopPropagation();
                const updatedResults = data.results.reduce((acc, rec) => {
                  if (rec.id === id) {
                    if (rec.isExpanded) {
                      return [...acc, { ...rec, isExpanded: false }];
                    }
                    return [...acc, { ...rec, isExpanded: true }];
                  }
                  return [...acc, rec];
                }, []);
                setData({ ...data, results: updatedResults });
              };

              return isPartner ? (
                <DefaultButton
                  textId={isExpanded ? 'justHideClients' : 'justShowClients'}
                  type="button"
                  noBorder
                  onClick={handleShowClientsButton}
                />
              ) : null;
            },
          },
        ]
      : []),
  ];

  const renderRowSubComponent = useCallback(
    ({ rowData }) => {
      const userId = rowData.id;
      return (
        <InnerPartnershipUsersTable
          colspan={columns.length}
          userId={userId}
          isShowEmail={viewEmailPermission}
          isShowPhone={viewPhonePermission}
        />
      );
    },
    [columns.length]
  );

  /* eslint-enable */

  const onRowClick = ({ rowData }) => {
    history.push(`/partnership/clients/user/${rowData.id}/accounts/`);
  };

  return (
    <div className={classNames('PartnershipUsersTable')}>
      <NewPaginationTable
        data={data}
        loading={loading}
        columns={columns}
        onRowClick={onRowClick}
        getList={getList}
        location={location}
        history={history}
        params={params}
        renderRowSubComponent={renderRowSubComponent}
        isCustomTable
        noResponsive
      />
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

PartnershipUsersTable.propTypes = {
  data: PropTypes.object.isRequired,
  viewEmailPermission: PropTypes.bool.isRequired,
  viewPhonePermission: PropTypes.bool.isRequired,
  viewSubIbTreePermission: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  getList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default PartnershipUsersTable;
