import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BonusManagementTable {
    :global(.RowLink) {
      justify-content: flex-start;
    }

    .ReactTable {
      .rt-thead {
        min-width: 700px !important;
        .rt-tr {
          .rt-th {
            &:first-child {
              flex: 20 0 auto !important;
              width: 40px !important;
              opacity: 0;
            }
            &:nth-child(2) {
              flex: 120 0 auto !important;
              width: 120px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(3) {
              flex: 80 0 auto !important;
              width: 80px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(4) {
              flex: 80 0 auto !important;
              width: 80px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(5) {
              flex: 40 0 auto !important;
              width: 40px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
          }
        }
      }
      .rt-tbody {
        min-width: 700px !important;
        .rt-tr-group {
          margin-bottom: 1px;
          &:last-child {
            margin-bottom: rem(15px);
          }
          .rt-tr {
            .rt-td {
              p,
              span {
                font-size: rem(15px);
                overflow: hidden;
                text-overflow: ellipsis;
              }
              &:first-child {
                flex: 20 0 auto !important;
                width: 40px !important;
              }
              &:nth-child(2) {
                flex: 120 0 auto !important;
                width: 120px !important;
                margin-right: 10px;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
              }
              &:nth-child(3) {
                flex: 80 0 auto !important;
                width: 80px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                .BonusManagementTable__types {
                  display: flex;
                  flex-direction: column;
                }
              }
              &:nth-child(4) {
                flex: 80 0 auto !important;
                width: 80px !important;

                :global(.MoneyCell__inner) {
                  :global(span) {
                    font-size: rem(15px) !important;
                  }
                }

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
              }
              &:nth-child(5) {
                flex: 40 0 auto !important;
                width: 40px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
              }
            }
          }
        }
      }
    }

    .BonusManagementTable__logo {
      width: rem(30px);
      height: rem(30px);
      border-radius: 50%;
    }

    .ResponsiveTable__thead {
      min-width: 320px !important;

      span {
        flex: 50;
        text-align: center;
        padding: 5px 0;

        @include respond-to(sm) {
          text-align: left;
        }

        &:first-child {
          opacity: 0;
          flex: 1;
        }

        &:nth-child(3) {
          flex: 20;
        }

        &:nth-child(4) {
          flex: 30;
        }

        &:last-child {
          min-width: 60px;
          padding-right: rem(30px);
          flex: 1;
        }
      }
    }

    .ResponsiveTable__tbody {
      min-width: 320px !important;

      .ResponsiveTable__preview-main {
        height: 40px;
        .ResponsiveTable__row-item {
          flex: 50;
          display: flex;
          align-items: center;
          justify-content: center;

          &:first-child {
            flex: 1;
          }

          &:nth-child(3) {
            flex: 40;
          }
        }
      }

      .ResponsiveTable__preview-description {
        .ResponsiveTable__row-item {
          justify-content: flex-start;
          padding-left: 22%;
        }
      }

      .ResponsiveTable__details-item {
        padding-left: 15%;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .BonusManagementTable {
      .defaultType {
        color: ${colors.gray.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
