import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  :global(.RowLink) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    :global(.RowLink) {
      color: ${colors.black.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
